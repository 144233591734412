import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import {
  ADD_TO_CART_START,
  CART_LIST_START,
  DELETE_CART_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  addToCartSuccess,
  addToCartFailure,
  fetchCartListSuccess,
  fetchCartListFailure,
  deleteCartSuccess,
  deleteCartFailure,
  fetchCartListStart,
} from "../actions/CartAction";

function* addCartApi() {
  try {
    const inputData = yield select((state) => state.cart.cartInput.data);
    const response = yield api.postMethod("carts_save", inputData);
    if (response.data.success) {
      yield put(addToCartSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(addToCartFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addToCartFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCartListApi() {
  try {
    const inputData = yield select((state) => state.cart.cartListInput);
    const response = yield api.postMethod("carts_list", inputData);

    if (response.data.success) {
      yield put(fetchCartListSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchCartListFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchCartListFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}
function* deleteCartApi() {
  try {
    const inputData = yield select((state) => state.cart.deleteCartInput);
    const response = yield api.postMethod("carts_delete", inputData);

    if (response.data.success) {
      yield put(deleteCartSuccess(response.data.data));
      const input = {
        user_id: localStorage.getItem("customerID"),
      };
      yield put(fetchCartListStart(input));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(deleteCartFailure(response.data.error));
    }
  } catch (error) {
    yield put(deleteCartFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(CART_LIST_START, fetchCartListApi)]);
  yield all([yield takeLatest(ADD_TO_CART_START, addCartApi)]);
  yield all([yield takeLatest(DELETE_CART_START, deleteCartApi)]);
}
