import {
  ADD_TO_CART_START,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  CART_LIST_START,
  CART_LIST_SUCCESS,
  CART_LIST_FAILURE,
  DELETE_CART_START,
  DELETE_CART_SUCCESS,
  DELETE_CART_FAILURE,
} from "./ActionConstant";

// Add to card actions.

export function addToCartStart(data) {
  return {
    type: ADD_TO_CART_START,
    data,
  };
}

export function addToCartSuccess(data) {
  return {
    type: ADD_TO_CART_SUCCESS,
    data,
  };
}

export function addToCartFailure(error) {
  return {
    type: ADD_TO_CART_FAILURE,
    error,
  };
}

// Get Cart list  actions.

export function fetchCartListStart(data) {
  return {
    type: CART_LIST_START,
    data,
  };
}

export function fetchCartListSuccess(data) {
  return {
    type: CART_LIST_SUCCESS,
    data,
  };
}

export function fetchCartListFailure(error) {
  return {
    type: CART_LIST_FAILURE,
    error,
  };
}

// Delete cart action

export function deleteCartStart(data) {
  return {
    type: DELETE_CART_START,
    data,
  };
}

export function deleteCartSuccess(data) {
  return {
    type: DELETE_CART_SUCCESS,
    data,
  };
}

export function deleteCartFailure(error) {
  return {
    type: DELETE_CART_FAILURE,
    error,
  };
}
