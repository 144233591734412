export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const STORE_CALLS_START = "STORE_CALLS_START";
export const STORE_CALLS_SUCCESS = "STORE_CALLS_SUCCESS";
export const STORE_CALLS_FAILURE = "STORE_CALLS_FAILURE";

export const FETCH_CALL_HISTORY_START = "FETCH_CALL_HISTORY_START";
export const FETCH_CALL_HISTORY_SUCCESS = "FETCH_CALL_HISTORY_SUCCESS";
export const FETCH_CALL_HISTORY_FAILURE = "FETCH_CALL_HISTORY_FAILURE";

export const FETCH_INCOMING_CALLS_START = "FETCH_INCOMING_CALLS_START";
export const FETCH_INCOMING_CALLS_SUCCESS = "FETCH_INCOMING_CALLS_SUCCESS";
export const FETCH_INCOMING_CALLS_FAILURE = "FETCH_INCOMING_CALLS_FAILURE";

export const ACCEPT_CALLS_START = "ACCEPT_CALLS_START";
export const ACCEPT_CALLS_SUCCESS = "ACCEPT_CALLS_SUCCESS";
export const ACCEPT_CALLS_FAILURE = "ACCEPT_CALLS_FAILURE";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";

export const FETCH_ALL_PRODUCTS_START = "FETCH_ALL_PRODUCTS_START";
export const FETCH_ALL_PRODUCTS_SUCCESS = "FETCH_ALL_PRODUCTS_SUCCESS";
export const FETCH_ALL_PRODUCTS_FAILURE = "FETCH_ALL_PRODUCTS_FAILURE";

export const FETCH_SINGLE_PRODUCT_START = "FETCH_SINGLE_PRODUCT_START";
export const FETCH_SINGLE_PRODUCT_SUCCESS = "FETCH_SINGLE_PRODUCT_SUCCESS";
export const FETCH_SINGLE_PRODUCT_FAILURE = "FETCH_SINGLE_PRODUCT_FAILURE";

export const FETCH_PURCHASE_HISTORY_START = "FETCH_PURCHASE_HISTORY_START";
export const FETCH_PURCHASE_HISTORY_SUCCESS = "FETCH_PURCHASE_HISTORY_SUCCESS";
export const FETCH_PURCHASE_HISTORY_FAILURE = "FETCH_PURCHASE_HISTORY_FAILURE";

export const FETCH_SINGLE_PURCHASE_HISTORY_START =
  "FETCH_SINGLE_PURCHASE_HISTORY_START";
export const FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS =
  "FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS";
export const FETCH_SINGLE_PURCHASE_HISTORY_FAILURE =
  "FETCH_SINGLE_PURCHASE_HISTORY_FAILURE";

export const FETCH_CUSTOMER_DETAILS_START = "FETCH_CUSTOMER_DETAILS_START";
export const FETCH_CUSTOMER_DETAILS_SUCCESS = "FETCH_CUSTOMER_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_DETAILS_FAILURE = "FETCH_CUSTOMER_DETAILS_FAILURE";

export const ADD_TO_CART_START = "ADD_TO_CART_START";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const EDIT_CART_START = "EDIT_CART_START";
export const EDIT_CART_SUCCESS = "EDIT_CART_SUCCESS";
export const EDIT_CART_FAILURE = "EDIT_CART_FAILURE";

export const CART_LIST_START = "CART_LIST_START";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAILURE = "CART_LIST_FAILURE";

export const DELETE_CART_START = "DELETE_CART_START";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const FETCH_ALL_ORDERS_START = "FETCH_ALL_ORDERS_START";
export const FETCH_ALL_ORDERS_SUCCESS = "FETCH_ALL_ORDERS_SUCCESS";
export const FETCH_ALL_ORDERS_FAILURE = "FETCH_ALL_ORDERS_FAILURE";

export const FETCH_SINGLE_ORDER_START = "FETCH_SINGLE_ORDER_START";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAILURE = "FETCH_SINGLE_ORDER_FAILURE";

export const ORDER_STATUS_UPDATE_START = "ORDER_STATUS_UPDATE_START";
export const ORDER_STATUS_UPDATE_SUCCESS = "ORDER_STATUS_UPDATE_SUCCESS";
export const ORDER_STATUS_UPDATE_FAILURE = "ORDER_STATUS_UPDATE_FAILURE";

export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";