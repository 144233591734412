import {
  STORE_CALLS_START,
  STORE_CALLS_SUCCESS,
  STORE_CALLS_FAILURE,
  FETCH_CALL_HISTORY_START,
  FETCH_CALL_HISTORY_SUCCESS,
  FETCH_CALL_HISTORY_FAILURE,
  FETCH_INCOMING_CALLS_START,
  FETCH_INCOMING_CALLS_SUCCESS,
  FETCH_INCOMING_CALLS_FAILURE,
  ACCEPT_CALLS_START,
  ACCEPT_CALLS_SUCCESS,
  ACCEPT_CALLS_FAILURE,
} from "./ActionConstant";

// Get user details actions.

export function fetchStoreCallsStart(data) {
  return {
    type: STORE_CALLS_START,
    data,
  };
}

export function fetchStoreCallsSuccess(data) {
  return {
    type: STORE_CALLS_SUCCESS,
    data,
  };
}

export function fetchStoreCallsFailure(error) {
  return {
    type: STORE_CALLS_FAILURE,
    error,
  };
}

// Get Call history details actions.

export function fetchCallHistoryStart(data) {
  return {
    type: FETCH_CALL_HISTORY_START,
    data,
  };
}

export function fetchCallHistorySuccess(data) {
  return {
    type: FETCH_CALL_HISTORY_SUCCESS,
    data,
  };
}

export function fetchCallHistoryFailure(error) {
  return {
    type: FETCH_CALL_HISTORY_FAILURE,
    error,
  };
}

// Get Call history details actions.

export function fetchIncomingCallsStart(data) {
  return {
    type: FETCH_INCOMING_CALLS_START,
    data,
  };
}

export function fetchIncomingCallsSuccess(data) {
  return {
    type: FETCH_INCOMING_CALLS_SUCCESS,
    data,
  };
}

export function fetchIncomingCallsFailure(error) {
  return {
    type: FETCH_INCOMING_CALLS_FAILURE,
    error,
  };
}
// Get Call history details actions.

export function acceptCallsStart(data) {
  return {
    type: ACCEPT_CALLS_START,
    data,
  };
}

export function acceptCallsSuccess(data) {
  return {
    type: ACCEPT_CALLS_SUCCESS,
    data,
  };
}

export function acceptCallsFailure(error) {
  return {
    type: ACCEPT_CALLS_FAILURE,
    error,
  };
}
