import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import { FETCH_CUSTOMER_DETAILS_START } from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchCustomerDetailsSuccess,
  fetchCustomerDetailsFailure,
} from "../actions/CustomerAction";

function* customerDetailsApi() {
  try {
    const inputData = yield select((state) => state.customer.userId);
    const response = yield api.postMethod("user_details", inputData);
    yield put(fetchCustomerDetailsSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCustomerDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_CUSTOMER_DETAILS_START, customerDetailsApi),
  ]);
}
