import React, { Component } from "react";
import { connect } from "react-redux";
import { Notify } from "react-redux-notify";

import { fetchStoreCallsStart } from "../../store/actions/CallsAction";

class HomeIndex extends Component {
  state = {};
  componentDidMount() {
    this.props.dispatch(fetchStoreCallsStart());
  }
  render() {
    const { storeCall } = this.props.storeCalls;
    return (
      <div className="col-md-12">
        <Notify position="BottomLeft" />
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Call List</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead className=" text-primary">
                  <th>Sl.no</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Purchase History</th>
                  <th className="text-right">Accept Call</th>
                </thead>
                <tbody>
                  {storeCall.loading
                    ? "Loading..."
                    : storeCall.data.calls.length > 0
                    ? storeCall.data.calls.map((calls) => (
                        <tr>
                          <td>Dakota Rice</td>
                          <td>
                            {" "}
                            <img
                              className="avatar border-gray"
                              src="../assets/img/mike.jpg"
                              alt="..."
                            />
                          </td>
                          <td>Oud-Turnhout</td>
                          <td>
                            <button
                              type="submit"
                              className="btn btn-primary btn-round"
                            >
                              See history
                            </button>
                          </td>
                          <td className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary btn-round"
                            >
                              Accept
                            </button>
                          </td>
                        </tr>
                      ))
                    : "No Calls found"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  storeCalls: state.storeCall,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);
