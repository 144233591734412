import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getLoginInputData,
  userLoginStart,
} from "../../store/actions/UserAction";

class LoginIndex extends Component {
  state = {};
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getLoginInputData(input.name, input.value));
  };
  handleLoginSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userLoginStart());
  };
  render() {
    const inputData = this.props.inputData.loginInputData;

    return (
      <>
        <div className="col-md-6 offset-3">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">Welcome! Login Now</h5>
              <p className="text-muted">Let's start</p>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={inputData.data.email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={inputData.data.password}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="update ml-auto mr-auto">
                    <button
                      type="submit"
                      className="btn btn-primary btn-round"
                      onClick={this.handleLoginSubmit}
                      disabled={this.props.inputData.buttonDisable}
                    >
                      {this.props.inputData.loadingButtonContent != null
                        ? this.props.inputData.loadingButtonContent
                        : "Login"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
