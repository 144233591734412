import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import {
  FETCH_PURCHASE_HISTORY_START,
  FETCH_SINGLE_PURCHASE_HISTORY_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchPurchaseHistorySuccess,
  fetchPurchaseHistoryFailure,
  fetchSinglePurchaseHistorySuccess,
  fetchSinglePurchaseHistoryFailure,
} from "../actions/PurchaseHistoryAction";

function* purchaseHistoryApi() {
  try {
    const inputData = yield select(
      (state) => state.purchaseHistory.purchaseInputData
    );
    const response = yield api.postMethod("user_store_orders_list", inputData);
    yield put(fetchPurchaseHistorySuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPurchaseHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* singlePurchaseHistoryApi() {
  try {
    const inputData = yield select(
      (state) => state.purchaseHistory.singlePurchaseInput
    );
    const response = yield api.postMethod("user_store_orders_view", inputData);
    yield put(fetchSinglePurchaseHistorySuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSinglePurchaseHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_PURCHASE_HISTORY_START, purchaseHistoryApi),
    yield takeLatest(
      FETCH_SINGLE_PURCHASE_HISTORY_START,
      singlePurchaseHistoryApi
    ),
  ]);
}
