import React, { Component } from "react";
import { connect } from "react-redux";

import {
  editChangePassword,
  changePasswordStart,
} from "../../../store/actions/UserAction";

class ChangePasswordIndex extends Component {
  state = {
    changePasswordData: {},
    loadingContent: null,
    buttonDisable: false,
  };
  componentDidMount() {
    console.log("Profile data from profile page", this.props.userData);
  }
  handleChangePassword = ({ currentTarget: input }) => {
    this.props.dispatch(editChangePassword(input.name, input.value));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(changePasswordStart());
  };

  render() {
    const { changePasswordData, loadingContent, buttonDisable } = this.state;
    return (
      <>
        <div className="col-md-6">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">Change Password</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Current Password</label>
                      <input
                        type="password"
                        name="old_password"
                        value={changePasswordData.old_password}
                        className="form-control"
                        onChange={this.handleChangePassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        name="password"
                        value={changePasswordData.password}
                        className="form-control"
                        onChange={this.handleChangePassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="password_confirmation"
                        value={changePasswordData.password_confirmation}
                        className="form-control"
                        onChange={this.handleChangePassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="update ml-auto mr-auto">
                    <button
                      type="submit"
                      className="btn btn-primary btn-round"
                      onClick={this.handleSubmit}
                      disabled={this.props.inputData.buttonDisable}
                    >
                      {this.props.inputData.loadingButtonContent != null
                        ? this.props.inputData.loadingButtonContent
                        : "Change Password"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
  inputData: state.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePasswordIndex);
