import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSinglePurchaseHistoryStart } from "../../store/actions/PurchaseHistoryAction";

class SinglePurchaseHistory extends Component {
  state = {};
  componentDidMount() {
    this.props.dispatch(
      fetchSinglePurchaseHistoryStart({
        store_order_id: this.props.match.params.id,
      })
    );
  }
  render() {
    const { singlePurchaseData } = this.props.purchaseHistory;
    return (
      <div>
        {singlePurchaseData.loading
          ? "Loading..."
          : singlePurchaseData.data.store_order_products.length > 0
          ? singlePurchaseData.data.store_order_products.map((products) => (
              <div className="product-history-sec card">
                <div className="card-body">
                  <div className="container">
                    <div className="produt-history-box">
                      <div className="row">
                        <div className="col-md-2 padding-left-zero">
                          <div className="p-history-img">
                            <img
                              src={products.product_picture}
                              className="history-img"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="p-history-details">
                            <h3 className="title">{products.product_name}</h3>
                            <p className="desc">
                              Canton Runner Lp Running Shoes For Men (Grey)
                            </p>
                            <h5 className="quantity">
                              No of Quantity:{" "}
                              <span className="ml-2">{products.quantity}</span>
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <h3 className="amount">{products.total_formatted}</h3>
                        </div>
                        <div className="col-md-4">
                          <div className="status">
                            <h6 className="delivery-status">
                              Delivered on July 21, 2010
                            </h6>
                            <small>Your item has been delivered</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "No product available"}
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  purchaseHistory: state.purchaseHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SinglePurchaseHistory);
