import React, { Component, useState } from "react";
import { connect } from "react-redux";

import {
  fetchUserDetailsStart,
  editUserDetails,
  updateUserDetailsStart,
} from "../../../store/actions/UserAction";

class ProfileIndex extends Component {
  state = {
    loading: true,
    inputData: {
      name: "",
      email: "",
      description: "",
    },
    loadingContent: null,
    buttonDisable: false,
  };
  componentDidMount() {
    this.props.dispatch(fetchUserDetailsStart());
  }

  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editUserDetails(input.name, input.value));
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      inputData["name"] = this.state.profileData.name;
      inputData["email"] = this.state.profileData.email;
      inputData["description"] = this.state.profileData.description;
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.updateProfileApiCall(this.state.inputData);
      }, 1000);
    }
  };

  updateProfile = (event) => {
    event.preventDefault();
    this.props.dispatch(updateUserDetailsStart());
  };

  render() {
    const userData = this.props.userData.profile;
    console.log("Testing userData", userData);

    if (userData.loading == true) {
      return "Loading..";
    } else {
      return (
        <>
          <div className="col-md-4">
            <div className="card card-user">
              <div className="image">
                <img src="../assets/img/damir-bosnjak.jpg" alt="..." />
              </div>
              <div className="card-body">
                <div className="author">
                  <a href="#">
                    <img
                      className="avatar border-gray"
                      src="../assets/img/mike.jpg"
                      alt="..."
                    />
                    <h5 className="title">{userData.data.name}</h5>
                  </a>
                  <p className="description">{userData.data.email}</p>
                </div>
                <p className="description text-center">
                  {userData.data.description}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card card-user">
              <div className="card-header">
                <h5 className="card-title">Edit Profile</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Home Address"
                          value={userData.data.name}
                          name="name"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Home Address"
                          value={userData.data.email}
                          name="email"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="avatar border-gray"
                        src="../assets/img/mike.jpg"
                        alt="..."
                      />
                      <div className="form-group">
                        <label>Image</label>
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          placeholder="Profile image"
                          name="picture"
                          onChange={this.handleChangeImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>About Me</label>
                        <textarea
                          className="form-control textarea"
                          name="description"
                          onChange={this.handleChange}
                        >
                          {userData.data.description}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="update ml-auto mr-auto">
                      <button
                        type="submit"
                        className="btn btn-primary btn-round"
                        onClick={this.updateProfile}
                        disabled={this.props.userData.buttonDisable}
                      >
                        {this.props.userData.loadingButtonContent != null
                          ? this.props.userData.loadingButtonContent
                          : "Update Profile"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
