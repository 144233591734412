import React, { Component } from "react";

import { Provider } from "react-redux";

import Base from "./components/Base";

import store from "./store";
import "react-redux-notify/dist/ReactReduxNotify.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Base />
      </Provider>
    );
  }
}

export default App;
