import React, { Component } from "react";
import { connect } from "react-redux";
import { Notify } from "react-redux-notify";

import {
  acceptCallsStart,
  fetchIncomingCallsStart,
} from "../../store/actions/CallsAction";

class IncomingCallsIndex extends Component {
  state = {};
  componentDidMount() {
    if (this.props.incomingCalls.loading)
      this.props.dispatch(fetchIncomingCallsStart());
  }
  acceptCall = (event, call) => {
    event.preventDefault();

    this.props.dispatch(acceptCallsStart({ store_call_id: call.unique_id }));
  };
  render() {
    const calls = this.props.incomingCalls;
    return (
      <div className="col-md-12">
        <Notify position="BottomLeft" />
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Incoming Calls</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead className=" text-primary">
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Called At</th>
                  <th>Status</th>
                  <th className="text-right">Action</th>
                </thead>
                <tbody>
                  {calls.loading
                    ? "Loading..."
                    : calls.data.calls.length > 0
                    ? calls.data.calls.map((call, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {call.username}
                            <img
                              className="avatar border-gray"
                              src={call.user_picture}
                              alt="..."
                            />
                          </td>
                          <td>{call.created}</td>
                          <td>{call.status_formatted}</td>
                          <td className="text-right">
                            {call.accept_btn_status == 1 ? (
                              <button
                                className="btn btn-primary btn-round"
                                onClick={(event) =>
                                  this.acceptCall(event, call)
                                }
                              >
                                Accept
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : "No Calls found"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  incomingCalls: state.storeCall.incomingCalls,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(IncomingCallsIndex);
