import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import CallsReducer from "./CallsReducer";
import notifyReducer from "react-redux-notify";
import ProductReducer from "./ProductReducer";
import PurchaseHistoryReducer from "./PurchaseHistoryReducer";
import CustomerReducer from "./CustomerReducer";
import CartReducer from "./CartReducer";
import OrderReducer from "./OrderReducer";
import DashboardReducer from "./DashboardReducer";


export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  storeCall: CallsReducer,
  notifications: notifyReducer,
  products: ProductReducer,
  purchaseHistory: PurchaseHistoryReducer,
  customer: CustomerReducer,
  cart: CartReducer,
  orders: OrderReducer,
  dashboard :DashboardReducer,
});
