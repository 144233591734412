import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchCartListStart,
  deleteCartStart,
} from "../../store/actions/CartAction";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

class CartIndex extends Component {
  state = {
    cartInput: {},
  };
  componentDidMount() {
    if (localStorage.getItem("customerID") != undefined) {
      const inputData = {
        user_id: 1,
      };
      if (this.props.cart.cartDetails.loading)
        this.props.dispatch(fetchCartListStart(inputData));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Customer Id not found."
      );
      this.props.dispatch(createNotification(notificationMessage));
    }
  }

  deleteCart = (event, product) => {
    const inputData = {
      cart_id: product.cart_id,
      user_id: localStorage.getItem("customerID"),
    };
    this.props.dispatch(deleteCartStart(inputData));
  };

  increament = (event, product) => {
    const cartInput = { ...this.state.cartInput };
  };

  render() {
    const { cartDetails } = this.props.cart;
    return (
      <div>
        {cartDetails.loading
          ? "Loading...."
          : cartDetails.data.cart_details.length > 0
          ? cartDetails.data.cart_details.map((product) => (
              <div className="view-cart-sec card">
                <div className="card-body">
                  <div className="container">
                    <div className="view-cart-box">
                      <div className="row">
                        <div className="col-md-3 padding-left-zero">
                          <div className="v-cart-img">
                            <img
                              src={product.picture}
                              className="view-cart-img"
                            />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="v-cart-details">
                            <h3 className="title">{product.product_name}</h3>
                            <p className="desc">
                              Amount: {product.total_price_formatted}
                            </p>
                            <form className="view-cart-form">
                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="fa fa-minus"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  name="quant[1]"
                                  class="form-control input-number"
                                  value="1"
                                  min="1"
                                  max="10"
                                />
                                <div
                                  class="input-group-append"
                                  onClick={(event) =>
                                    this.increament(event, product)
                                  }
                                >
                                  <span class="input-group-text">
                                    <i class="fa fa-plus"></i>
                                  </span>
                                </div>
                              </div>
                            </form>
                            <button
                              className="btn btn-danger mb-0"
                              onClick={(event) =>
                                this.deleteCart(event, product)
                              }
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "No data Found"}
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  products: state.products,
  cart: state.cart,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CartIndex);
