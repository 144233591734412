import {
    DASHBOARD_START,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAILURE,
  } from "./ActionConstant";
  
  // Get all orders actions.
  
  export function fetchStoreDashboardStart(data) {
    return {
      type: DASHBOARD_START,
      data,
    };
  }
  
  export function fetchStoreDashboardSuccess(data) {
    return {
      type: DASHBOARD_SUCCESS,
      data,
    };
  }
  
  export function fetchStoreDashboardFailure(error) {
    return {
      type: DASHBOARD_FAILURE,
      error,
    };
  }
