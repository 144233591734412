import React, { Component } from "react";
import { fetchAllOrdersStart } from "../../store/actions/OrderAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

class OrderListIndex extends Component {
  state = {
    setIsFetchingAllProd : false,
    setLoadMoreData:true,
  };
  componentDidMount() {
    if (this.props.orders.allOrders.loading) {
      this.props.dispatch(fetchAllOrdersStart());
    }
  }
  
  loadMoreProduct() {
    this.setState({
      setIsFetchingAllProd: true,
    });
    if (
      this.props.orders.totalItem !=
      this.props.orders.allOrders.data.length
    ) {
      this.props.dispatch(fetchAllOrdersStart());
      setTimeout(() => {
        this.setState({
          setIsFetchingAllProd: true,
        });
      }, 1000);
    } else {
      this.setState({
        setIsFetchingAllProd: false,
        setLoadMoreData:false,
      });
    }
  }

  render() {
    const { allOrders } = this.props.orders;
    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Product lists</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive product-list-tabel">
              <table className="table">
                <thead className=" text-primary">
                  <th>Sl.no</th>
                  <th>User Name</th>
                  <th>Order Placed Date</th>
                  <th>Total Products</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>View</th>
                </thead>
                <tbody>
                  {allOrders.loading
                    ? "Loading..."
                    : allOrders.data.length > 0
                    ? allOrders.data.map((orders, i) => (
                        <tr>
                          <td>{i+1}</td>
                          <td>
                          {orders.user_name}
                          </td>
                          <td>{orders.order_placed_date}</td>
                          <td>{orders.total_products}</td>
                          <td>{orders.total_formatted}</td>
                          <td>
                            {orders.status_formatted}
                          </td>
                          <td className="text-right">
                            <Link
                              to={`/store-order-details/${orders.store_order_id}`}
                              className="btn btn-primary-outline btn-round"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    : "No data found"}
                </tbody>
              </table>
              <button
                  className="btn btn-primary mr-2 btn-round"
                  type="submit"
                  onClick={() => this.loadMoreProduct()}
                >
                  Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  orders: state.orders,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OrderListIndex);
