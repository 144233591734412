import { all, fork } from "redux-saga/effects";

import UserSaga from "./UserSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import CallsSaga from "./CallsSaga";
import ProductSaga from "./ProductSaga";
import PurchaseHistorySaga from "./PurchaseHistorySaga";
import CustomerSaga from "./CustomerSaga";
import CartSaga from "./CartSaga";
import OrderSaga from "./OrderSaga";
import DashboardSaga from "./DashboardSaga";


export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(ChangePasswordSaga)]);
  yield all([fork(CallsSaga)]);
  yield all([fork(ProductSaga)]);
  yield all([fork(PurchaseHistorySaga)]);
  yield all([fork(CustomerSaga)]);
  yield all([fork(CartSaga)]);
  yield all([fork(OrderSaga)]);
  yield all([fork(DashboardSaga)]);

}
