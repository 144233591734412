import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCustomerDetailsStart } from "../../store/actions/CustomerAction";

class SingleUserDetails extends Component {
  state = {};
  componentDidMount() {
    this.props.dispatch(
      fetchCustomerDetailsStart({ user_id: this.props.match.params.id })
    );
  }
  render() {
    const { customer } = this.props.customer;
    return (
      <div className="col-md-4">
        {customer.loading ? (
          "Loading..."
        ) : (
          <div className="card card-user">
            <div className="image">
              <img src={customer.data.picture} alt="..." />
            </div>
            <div className="card-body">
              <div className="author">
                <a href="#">
                  <img
                    className="avatar border-gray"
                    src={customer.data.picture}
                    alt="..."
                  />
                  <h5 className="title">{customer.data.name}</h5>
                </a>
                <p className="description">@chetfaker</p>
              </div>
              <p className="description text-center">
                {customer.data.description}
              </p>
            </div>
            <div className="card-footer">
              <hr />
              <div className="button-container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-6 ml-auto">
                    <h5>
                      Total Order
                      <br />
                      <small>Files</small>
                    </h5>
                  </div>
                  <div className="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
                    <h5>
                      Cancelled
                      <br />
                      <small>Used</small>
                    </h5>
                  </div>
                  <div className="col-lg-3 mr-auto">
                    <h5>
                      24,6$
                      <br />
                      <small>Spent</small>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  customer: state.customer,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleUserDetails);
