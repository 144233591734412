import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import {
  FETCH_ALL_ORDERS_START,
  FETCH_SINGLE_ORDER_START,
  ORDER_STATUS_UPDATE_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchAllOrdersSuccess,
  fetchAllOrdersFailure,
  fetchSingleOrderSuccess,
  fetchSingleOrderFailure,
  orderStatusUpdateSuccess,
  orderStatusUpdateFailure,
} from "../actions/OrderAction";

function* fetchAllOrdersApi() {
  try {
    
    const skipCount = yield select(
      (state) => state.orders.allOrders.skip
    );
    const response = yield api.postMethod("store_orders", {
      skip: skipCount,
    });
    if (response.data.success) {
      // Do nothing.
      yield put(fetchAllOrdersSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchAllOrdersFailure(response.data.error));
      // window.location.assign("/");
    }
  } catch (error) {
    yield put(fetchAllOrdersFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
    // window.location.assign("/");
  }
}

function* fetchSingleOrderApi() {
  try {
    const inputData = yield select((state) => state.orders.singleOrderID);
    const response = yield api.postMethod("store_order_details", inputData);
    yield put(fetchSingleOrderSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleOrderFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* orderStatusUpdateApi() {
  try {
    const inputData = yield select((state) => state.orders.singleOrderID);
    const response = yield api.postMethod("store_order_status", inputData);
    yield put(orderStatusUpdateSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleOrderFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_ALL_ORDERS_START, fetchAllOrdersApi)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_ORDER_START, fetchSingleOrderApi),
  ]);
  yield all([
    yield takeLatest(ORDER_STATUS_UPDATE_START, orderStatusUpdateApi),
  ]);
}
