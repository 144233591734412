import React, { Component } from "react";

import { createBrowserHistory as createHistory } from "history";

import { Router, Switch, Route, Redirect } from "react-router-dom";

import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import MainLayout from "../layouts/MainLayout";
import HomeIndex from "../Home/HomeIndex";
import LoginIndex from "../Auth/LoginIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import ChangePasswordIndex from "../Accounts/ChangePassword/ChangePasswordIndex";
import UserPurchaseHistory from "../UserDetails/UserPurchaseHistory";
import Logout from "../Auth/Logout";
import BroadcastIndex from "../broadcastPage/BroadcastIndex";
import LayoutWithoutNav from "../layouts/LayoutWithoutNav";
import ProductListIndex from "../Products/ProductListIndex";
import SingleProductIndex from "../Products/SingleProductIndex";
import SingleUserDetails from "../UserDetails/SignleUserDetails";
import SinglePurchaseHistory from "../UserDetails/SinglePurchaseHistory";
import CartIndex from "../Cart/CartIndex";
import CallHistoryIndex from "../Calls/CallHistoryIndex";
import IncomingCallsIndex from "../Calls/IncomingCallsIndex";
import OrderListIndex from "../Orders/OrderListIndex";
import SingleOrderIndex from "../Orders/SingleOrderIndex";
import DashboardIndex from "../Home/DashboardIndex";

const history = createHistory();

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: false,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/"}
            component={CallHistoryIndex}
            layout={MainLayout}
            exact
          />
          <AppRoute
            path={"/login"}
            component={LoginIndex}
            layout={LayoutWithoutNav}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/forgot-password"}
            component={ForgotPassword}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePasswordIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/user-details/:id"}
            component={SingleUserDetails}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/user/purchase/history/:id"}
            component={UserPurchaseHistory}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/user/single-purchase/history/:id"}
            component={SinglePurchaseHistory}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/store-products"}
            component={ProductListIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/store-orders"}
            component={OrderListIndex}
            layout={MainLayout}
          />
           <PrivateRoute
            authentication={this.state.authentication}
            path={"/store-order-details/:id"}
            component={SingleOrderIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/store-product-details/:id"}
            component={SingleProductIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cart"}
            component={CartIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/video-call/:username"}
            component={BroadcastIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/call/history"}
            component={CallHistoryIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/incoming-calls"}
            component={IncomingCallsIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashboardIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
