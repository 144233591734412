import React, { Component } from "react";
import { Link } from "react-router-dom";

class SideBar extends Component {
  render() {
    return (
      <div className="sidebar" data-color="white" data-active-color="danger">
        <div className="logo">
          <a
            href="#"
            className="simple-text logo-normal"
          >
            TubeNow - Akumo
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            <li className="">
              <Link to={"/dashboard"}>
                <i className="nc-icon nc-bank"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li className="">
              <Link to={"/incoming-calls"}>
                <i className="nc-icon nc-headphones"></i>
                <p>Incoming Calls</p>
              </Link>
            </li>
            <li className="">
              <Link to={"/call/history"}>
                <i className="nc-icon nc-book-bookmark"></i>
                <p>Call History</p>
              </Link>
            </li>
            <li className="">
              <Link to={"/store-products"}>
                <i className="nc-icon nc-basket"></i>
                <p>Store products</p>
              </Link>
            </li>
            <li className="">
              <Link to={"/store-orders"}>
                <i className="nc-icon nc-basket"></i>
                <p>Store orders</p>
              </Link>
            </li>
            <li>
              <Link to={"/profile"}>
                <i className="nc-icon nc-diamond"></i>
                <p>Profile</p>
              </Link>
            </li>
            <li>
              <Link to={"/logout"}>
                <i className="nc-icon nc-pin-3"></i>
                <p>Logout</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
