import {
  FETCH_ALL_PRODUCTS_START,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_ALL_PRODUCTS_FAILURE,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  allProducts: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
    totalItem: 0,
  },
  buttonDisable: false,
  loadingButtonContent: null,
  singleProductID: null,
  singleProduct: {
    data: {},
    loading: true,
    error: false,
  },
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PRODUCTS_START:
      return {
        ...state,
        allProducts: {
          data: {},
          loading: state.allProducts.loading ? true : false,
          error: false,
          skip: state.allProducts.skip,
          totalItem: state.allProducts.totalItem,
        },
      };
    case FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: {
          data: action.data.products,
          loading: false,
          error: false,
          totalItem: action.data.total,
          skip: action.data.products.length + state.allProducts.skip,
        },
      };
    case FETCH_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        allProducts: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_PRODUCT_START:
      return {
        ...state,
        singleProductID: action.data,
        singleProduct: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,

        singleProduct: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        singleProduct: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default ProductReducer;
