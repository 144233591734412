import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import {
    DASHBOARD_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
    fetchStoreDashboardSuccess,
    fetchStoreDashboardFailure,
} from "../actions/DashboardAction";

function* fetchStoreDashboardApi() {
  try {
    const response = yield api.postMethod("store_dashboard");
    yield put(fetchStoreDashboardSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchStoreDashboardFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(DASHBOARD_START, fetchStoreDashboardApi),
  ]);
}
