import React, { Component } from "react";
import { fetchSingleProductStart } from "../../store/actions/ProductAction";
import { connect } from "react-redux";
import { addToCartStart } from "../../store/actions/CartAction";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

class SingleProductIndex extends Component {
  state = {
    cartInput: {
      quantity: 1,
    },
  };
  componentDidMount() {
    this.props.dispatch(
      fetchSingleProductStart({ store_product_id: this.props.match.params.id })
    );
  }
  addToCart = (event, products) => {
    event.preventDefault();
    if (localStorage.getItem("customerID") != undefined) {
      const inputData = {
        store_product_id: products.store_product_id,
        user_id: 1,
        quantity: this.state.cartInput.quantity,
        status: 0,
      };
      this.props.dispatch(addToCartStart(inputData));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Customer Id not found."
      );
      this.props.dispatch(createNotification(notificationMessage));
    }
  };

  increament = (event) => {
    const cartInput = { ...this.state.cartInput };
    cartInput["quantity"] = this.state.cartInput.quantity + 1;
    this.setState({ cartInput });
  };

  decrement = (event) => {
    const cartInput = { ...this.state.cartInput };
    cartInput["quantity"] = this.state.cartInput.quantity - 1;
    this.setState({ cartInput });
  };

  handleChange = ({ currentTarget: input }) => {
    const cartInput = { ...this.state.cartInput };
    cartInput["quantity"] = parseInt(input.value);
    this.setState({ cartInput });
  };

  render() {
    const { singleProduct } = this.props.products;
    if (singleProduct.loading) return "Loading...";
    else
      return (
        <div className="product-view-sec card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="cart-main-img">
                    <img
                      src={singleProduct.data.picture}
                      className="main-product-img"
                    />
                  </div>
                  <div className="spacer-mg"></div>
                  {/* <div className="row">
                  <div className="col-md-3">
                    <div className="cart-sub-img">
                      <img
                        src="/assets/img/shoes-1.jpg"
                        className="sub-product-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cart-sub-img">
                      <img
                        src="/assets/img/shoes-1.jpg"
                        className="sub-product-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cart-sub-img">
                      <img
                        src="/assets/img/shoes-1.jpg"
                        className="sub-product-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cart-sub-img">
                      <img
                        src="/assets/img/shoes-1.jpg"
                        className="sub-product-img"
                      />
                    </div>
                  </div>
                </div>
             */}
                </div>
                <div className="col-md-5">
                  <div className="cart-details">
                    <h3 className="cart-title">{singleProduct.data.name}</h3>
                    <p className="cart-desc">
                      {singleProduct.data.description}
                    </p>
                    <form className="product-form">
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          onClick={this.decrement}
                        >
                          <span class="input-group-text">
                            <i class="fa fa-minus"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          name="quantity"
                          class="form-control input-number"
                          value={this.state.cartInput.quantity}
                          onChange={this.handleChange}
                        />
                        <div
                          class="input-group-append"
                          onClick={this.increament}
                        >
                          <span class="input-group-text">
                            <i class="fa fa-plus"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                    <h4 className="cart-amount">
                      {singleProduct.data.product_amount_formatted}
                    </h4>
                    <button
                      className="btn btn-primary"
                      onClick={(event) =>
                        this.addToCart(event, singleProduct.data)
                      }
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToPros = (state) => ({
  products: state.products,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleProductIndex);
