import React, { Component } from "react";
import { connect } from "react-redux";
import { Notify } from "react-redux-notify";

import { fetchStoreDashboardStart } from "../../store/actions/DashboardAction";

class DashboardIndex extends Component {
  state = {};
  componentDidMount() {
    this.props.dispatch(fetchStoreDashboardStart());
  }
  render() {
    const { storeDashboard } = this.props.dashboard;
    if (storeDashboard.loading) 
        return "Loading...";
    else
    return (
        <>  
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Today Orders</h4>
                        <h5>{storeDashboard.data.today_orders}</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Today Revenue</h4>
                        <h5>{storeDashboard.data.today_revenue}</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Total Revenue</h4>
                        <h5>{storeDashboard.data.total_revenue}</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Total Items Sold</h4>
                        <h5>{storeDashboard.data.total_items}</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Total Orders</h4>
                        <h5>{storeDashboard.data.total_orders}</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body" style={{padding:"50px",textAlign:"center"}}>
                        <h4 style={{color:"#51cbce"}}>Today Products</h4>
                        <h5>{storeDashboard.data.total_products}</h5>
                    </div>
                </div>
            </div>
            
        </>
    );
  }
}

const mapStateToPros = (state) => ({
  dashboard: state.dashboard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashboardIndex);
