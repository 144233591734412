import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchStoreCallsSuccess,
  fetchStoreCallsFailure,
  fetchCallHistorySuccess,
  fetchCallHistoryFailure,
  fetchIncomingCallsSuccess,
  fetchIncomingCallsFailure,
  acceptCallsFailure,
  acceptCallsSuccess,
} from "../actions/CallsAction";
import api from "../../Environment";
import {
  STORE_CALLS_START,
  FETCH_CALL_HISTORY_START,
  FETCH_INCOMING_CALLS_START,
  ACCEPT_CALLS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

function* getStoreCallsAPI() {
  try {
    const response = yield api.postMethod("store_calls_incoming");
    if (response.data.success) {
      yield put(fetchStoreCallsSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchStoreCallsFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchStoreCallsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCallHistoryAPI() {
  try {
    const response = yield api.postMethod("store_calls_history");
    if (response.data.success) {
      yield put(fetchCallHistorySuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchCallHistoryFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchCallHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* fetchIncomingCallsAPI() {
  try {
    const response = yield api.postMethod("store_calls_incoming");
    if (response.data.success) {
      yield put(fetchIncomingCallsSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchIncomingCallsFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchIncomingCallsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* acceptCallsAPI() {
  try {
    const inputData = yield select(
      (state) => state.storeCall.acceptCalls.inputData
    );
    const response = yield api.postMethod("store_calls_join", inputData);
    if (response.data.success) {
      yield put(acceptCallsSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(response.data.data.join_url);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(acceptCallsFailure(response.data.error));
    }
  } catch (error) {
    yield put(acceptCallsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(STORE_CALLS_START, getStoreCallsAPI)]);
  yield all([yield takeLatest(FETCH_CALL_HISTORY_START, fetchCallHistoryAPI)]);
  yield all([
    yield takeLatest(FETCH_INCOMING_CALLS_START, fetchIncomingCallsAPI),
    yield takeLatest(ACCEPT_CALLS_START, acceptCallsAPI),
  ]);
}
