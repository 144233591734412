import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";

import {
  FETCH_ALL_PRODUCTS_START,
  FETCH_SINGLE_PRODUCT_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchAllProductsSuccess,
  fetchAllProductsFailure,
  fetchSingleProductSuccess,
  fetchSingleProductFailure,
} from "../actions/ProductAction";

function* fetchAllProductsApi() {
  try {
    
    const skipCount = yield select(
      (state) => state.products.allProducts.skip
    );
    const response = yield api.postMethod("store_products_list", {
      skip: skipCount,
    });
    if (response.data.success) {
      // Do nothing.
      yield put(fetchAllProductsSuccess(response.data.data));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchAllProductsFailure(response.data.error));
      // window.location.assign("/");
    }
  } catch (error) {
    yield put(fetchAllProductsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
    // window.location.assign("/");
  }
}

function* fetchSingleProductApi() {
  try {
    const inputData = yield select((state) => state.products.singleProductID);
    const response = yield api.postMethod("store_products_view", inputData);
    yield put(fetchSingleProductSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing.
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleProductFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_ALL_PRODUCTS_START, fetchAllProductsApi)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_PRODUCT_START, fetchSingleProductApi),
  ]);
}
