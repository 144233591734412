import {
    FETCH_ALL_ORDERS_START,
    FETCH_ALL_ORDERS_SUCCESS,
    FETCH_ALL_ORDERS_FAILURE,
    FETCH_SINGLE_ORDER_START,
    FETCH_SINGLE_ORDER_SUCCESS,
    FETCH_SINGLE_ORDER_FAILURE,
    ORDER_STATUS_UPDATE_SUCCESS,
    ORDER_STATUS_UPDATE_START,
    ORDER_STATUS_UPDATE_FAILURE,
  } from "../actions/ActionConstant";
  
  const initialState = {
    allOrders: {
      data: {},
      loading: true,
      error: false,
      skip: 0,
      totalItem: 0,
    },
    buttonDisable: false,
    loadingButtonContent: null,
    singleOrderID: null,
    singleOrder: {
      data: {},
      loading: true,
      error: false,
    },
  };
  
  const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_ORDERS_START:
        return {
          ...state,
          allOrders: {
            data: {},
            loading: state.allOrders.loading ? true : false,
            error: false,
            skip: state.allOrders.skip,
            totalItem: state.allOrders.totalItem,
          },
        };
      case FETCH_ALL_ORDERS_SUCCESS:
        return {
          ...state,
          allOrders: {
            data: action.data.store_orders,
            loading: false,
            error: false,
            totalItem: action.data.total,
            skip: action.data.total + state.allOrders.skip,
          },
        };
      case FETCH_ALL_ORDERS_FAILURE:
        return {
          ...state,
          allOrders: {
            data: {},
            loading: true,
            error: action.error,
          },
        };
      case FETCH_SINGLE_ORDER_START:
        return {
          ...state,
          singleOrderID: action.data,
          singleOrder: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case FETCH_SINGLE_ORDER_SUCCESS:
        return {
          ...state,
  
          singleOrder: {
            data: action.data,
            loading: false,
            error: false,
          },
        };
      case FETCH_SINGLE_ORDER_FAILURE:
        return {
          ...state,
          singleOrder: {
            data: {},
            loading: false,
            error: action.error,
          },
        };
        case ORDER_STATUS_UPDATE_START:
          return {
            ...state,
            singleOrderID: action.data,
            singleOrder: {
              data: {},
              loading: true,
              error: false,
            },
          };
        case ORDER_STATUS_UPDATE_SUCCESS:
          return {
            ...state,
    
            singleOrder: {
              data: action.data,
              loading: false,
              error: false,
            },
          };
        case ORDER_STATUS_UPDATE_FAILURE:
          return {
            ...state,
            singleOrder: {
              data: {},
              loading: false,
              error: action.error,
            },
          };
      default:
        return state;
    }
  };
  
  export default OrderReducer;
  