import React, { Component } from "react";
import SideBar from "./header/SideBar";
import NavBar from "./header/NavBar";
import { Notify } from "react-redux-notify";

class MainLayout extends Component {
  state = {};
  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel" style={{ height: "100vh" }}>
          <NavBar />
          <div className="content">
            <Notify position="TopRight" />
            <div className="row">{React.cloneElement(this.props.children)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainLayout;
