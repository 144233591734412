import {
  FETCH_PURCHASE_HISTORY_START,
  FETCH_PURCHASE_HISTORY_SUCCESS,
  FETCH_PURCHASE_HISTORY_FAILURE,
  FETCH_SINGLE_PURCHASE_HISTORY_START,
  FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS,
  FETCH_SINGLE_PURCHASE_HISTORY_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  purchaseInputData: null,
  purchaseHistory: {
    data: {},
    loading: true,
    error: false,
  },
  singlePurchaseInput: null,
  singlePurchaseData: {
    data: {},
    loading: true,
    error: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const PurchaseHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_HISTORY_START:
      return {
        ...state,
        purchaseInputData: action.data,
        purchaseHistory: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        purchaseHistory: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        purchaseHistory: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SINGLE_PURCHASE_HISTORY_START:
      return {
        ...state,
        singlePurchaseInput: action.data,
        singlePurchaseData: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        singlePurchaseData: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        singlePurchaseData: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default PurchaseHistoryReducer;
