import {
  ADD_TO_CART_START,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  CART_LIST_START,
  CART_LIST_SUCCESS,
  CART_LIST_FAILURE,
  DELETE_CART_START,
  DELETE_CART_SUCCESS,
  DELETE_CART_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  cartInput: {
    data: {},
    loading: true,
    error: false,
  },
  addCartSuccess: {
    data: {},
    loading: true,
    error: false,
  },
  cartListInput: null,
  deleteCartInput: null,
  deleteCartSuccess: {
    data: {},
    loading: true,
    error: false,
  },
  cartDetails: {
    data: {},
    loading: true,
    error: false,
  },
  cartCount: 0,
  buttonDisable: false,
  loadingButtonContent: null,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_START:
      return {
        ...state,
        cartInput: {
          data: action.data,
          loading: true,
          error: false,
        },
        addCartSuccess: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addCartSuccess: {
          data: action.data,
          loading: false,
          error: false,
        },
        cartCount: state.cartCount + 1,
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        addCartSuccess: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case CART_LIST_START:
      return {
        ...state,
        cartListInput: action.data,
        cartDetails: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case CART_LIST_SUCCESS:
      return {
        ...state,
        cartDetails: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case CART_LIST_FAILURE:
      return {
        ...state,
        cartDetails: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case DELETE_CART_START:
      return {
        ...state,
        deleteCartInput: action.data,
        deleteCartSuccess: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case DELETE_CART_SUCCESS:
      return {
        ...state,
        deleteCartSuccess: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case DELETE_CART_FAILURE:
      return {
        ...state,
        deleteCartSuccess: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default CartReducer;
