import {
  FETCH_CUSTOMER_DETAILS_START,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_FAILURE,
} from "./ActionConstant";

// Get customer details actions.

export function fetchCustomerDetailsStart(data) {
  return {
    type: FETCH_CUSTOMER_DETAILS_START,
    data,
  };
}

export function fetchCustomerDetailsSuccess(data) {
  return {
    type: FETCH_CUSTOMER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchCustomerDetailsFailure(error) {
  return {
    type: FETCH_CUSTOMER_DETAILS_FAILURE,
    error,
  };
}
