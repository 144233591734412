import {
    DASHBOARD_START,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAILURE,
  } from "../actions/ActionConstant";
  
  const initialState = {
    storeDashboard: {
      data: {},
      loading: true,
      error: false,
    },
    buttonDisable: false,
    loadingButtonContent: null,
  };

  const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case DASHBOARD_START:
        return {
          ...state,
          storeDashboard: {
            data: {},
            loading: true,
            error: false,
          },
        };
      case DASHBOARD_SUCCESS:
        return {
          ...state,
          storeDashboard: {
            data: action.data,
            loading: false,
            error: false,
          },
        };
      case DASHBOARD_FAILURE:
        return {
          ...state,
          storeDashboard: {
            data: {},
            loading: true,
            error: action.error,
          },
        };
      default:
        return state;
    }
  };
  
  export default DashboardReducer;
  