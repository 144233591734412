import {
  FETCH_CUSTOMER_DETAILS_START,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  customer: {
    data: {},
    loading: true,
    error: false,
  },
  userId: null,
  buttonDisable: false,
  loadingButtonContent: null,
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS_START:
      return {
        ...state,
        userId: action.data,
        customer: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customer: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        customer: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default CustomerReducer;
