import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Notification from "react-web-notification";
import api from "../../../Environment";

const $ = window.$;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: ''
    };
  }

  componentDidMount() {
    
    this.handlePermissionGranted();

    const interval = setInterval(() => {
      api.postMethod("store_calls_incoming").then((response) => {
        if (response.data.success === true) {
          if(response.data.data.calls.length > 0) {
            this.handleButtonClick();
          }
        }
      });
    }, 120000);

    return () => clearInterval(interval);
  }

  handlePermissionGranted() {
    console.log("Permission Granted");
    this.setState({
      ignore: false
    });
  }
  handlePermissionDenied() {
    console.log("Permission Denied");
    this.setState({
      ignore: true
    });
  }
  handleNotSupported() {
    console.log("Web Notification not Supported");
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick(e, tag) {
    console.log(e, "Notification clicked tag:" + tag);
  }

  handleNotificationOnError(e, tag) {
    console.log(e, "Notification error tag:" + tag);
  }

  handleNotificationOnClose(e, tag) {
    console.log(e, "Notification closed tag:" + tag);
  }

  handleNotificationOnShow(e, tag) {
    this.playSound();
    console.log(e, "Notification shown tag:" + tag);
  }

  playSound(filename) {
    // document.getElementById("sound").play();
    let playAttempt = setInterval(() => {
      document.getElementById("sound").play()
        .then(() => {
          clearInterval(playAttempt);
        })
        .catch(error => {
          console.log('Unable to play the video, User has not interacted yet.');
        });
    }, 3000);
  }

  handleButtonClick = () => {
    if (this.state.ignore) {
      return;
    }

    const now = Date.now();

    const title = "New user call notification";
    const body = "User call Request from store";
    const tag = now;
    const icon =
      "http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png";
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: "en",
      dir: "ltr",
      sound: window.location.origin + "/assets/audio/ringtone.mp3"
    };
    this.setState({
      title: title,
      options: options
    });
  }

  handleButtonClick2() {
    this.props.swRegistration
      .getNotifications({})
      .then(function(notifications) {
        console.log(notifications);
      });
  }


  render() {
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <a className="navbar-brand" href="javascript:;"></a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navigation"
          >
            <ul className="navbar-nav">
            {/* <button onClick={this.handleButtonClick}>Notify Me</button> */}

              <div>
                {/* {document.title === "swExample" && (
                  <button onClick={this.handleButtonClick2.bind(this)}>
                    swRegistration.getNotifications
                  </button>
                )} */}
                <Notification
                  ignore={this.state.ignore && this.state.title !== ""}
                  notSupported={this.handleNotSupported.bind(this)}
                  onPermissionGranted={this.handlePermissionGranted.bind(this)}
                  onPermissionDenied={this.handlePermissionDenied.bind(this)}
                  onShow={this.handleNotificationOnShow.bind(this)}
                  onClick={this.handleNotificationOnClick.bind(this)}
                  onClose={this.handleNotificationOnClose.bind(this)}
                  onError={this.handleNotificationOnError.bind(this)}
                  timeout={10000}
                  title={this.state.title}
                  options={this.state.options}
                  swRegistration={this.props.swRegistration}
                />
                <audio id="sound" preload="auto" autoplay>
                  <source src={window.location.origin + "/assets/audio/ringtone.mp3"} type="audio/mpeg" />
                  <embed
                    hidden={true}
                    autostart="false"
                    loop={false}
                    src={window.location.origin + "/assets/audio/ringtone.mp3"}
                  />
                </audio>
              </div>
              <li className="nav-item">
                <Link to={"/cart"} className="nav-link">
                  <i class="fa fa-shopping-cart"></i>
                  <span class="badge badge-cart">
                    {this.props.cart.cartCount}
                  </span>
                </Link>
              </li>
              <li class="nav-item btn-rotate dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="http://example.com"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="nc-icon nc-settings-gear-65"></i>
                  <p>
                    <span class="d-lg-none d-md-block">Account</span>
                  </p>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link class="dropdown-item" to={"/profile"}>
                    Profile
                  </Link>
                  <Link class="dropdown-item" to={"/change-password"}>
                    Change Password
                  </Link>
                  <Link class="dropdown-item" to={"/logout"}>
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  products: state.products,
  cart: state.cart,
  incomingCalls: state.storeCall.incomingCalls,
});

export default connect(mapStateToPros, null)(NavBar);
