import React, { Component } from "react";
import { fetchPurchaseHistoryStart } from "../../store/actions/PurchaseHistoryAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class UserPurchaseHistory extends Component {
  state = {};
  componentDidMount() {
    if (this.props.purchaseHistory.purchaseHistory.loading) {
      this.props.dispatch(
        fetchPurchaseHistoryStart({ user_id: this.props.match.params.id })
      );
    }
  }
  render() {
    const { purchaseHistory } = this.props.purchaseHistory;
    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Purchase history page</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead className=" text-primary">
                  <th>Sl.no</th>
                  <th>Store Image</th>
                  <th>No of Products</th>
                  <th>Amount</th>
                  <th>Date of Purchase</th>
                  <th className="text-right">View Products</th>
                </thead>
                <tbody>
                  {purchaseHistory.loading
                    ? "Loading..."
                    : purchaseHistory.data.length > 0
                    ? purchaseHistory.data.map((orders, i) => (
                        <tr>
                          <td>{i}</td>
                          <td>Awesome Store</td>
                          <td>{orders.total_products}</td>
                          <td>{orders.total_formatted}</td>
                          <td>{orders.order_placed_date}</td>
                          <td className="text-right">
                            <Link
                              to={`/user/single-purchase/history/${orders.store_order_id}`}
                              className="btn btn-primary btn-round"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    : "No data Found"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  purchaseHistory: state.purchaseHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UserPurchaseHistory);
