import {
  FETCH_PURCHASE_HISTORY_START,
  FETCH_PURCHASE_HISTORY_SUCCESS,
  FETCH_PURCHASE_HISTORY_FAILURE,
  FETCH_SINGLE_PURCHASE_HISTORY_START,
  FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS,
  FETCH_SINGLE_PURCHASE_HISTORY_FAILURE,
} from "./ActionConstant";

// Get user purchase history actions.

export function fetchPurchaseHistoryStart(data) {
  return {
    type: FETCH_PURCHASE_HISTORY_START,
    data,
  };
}

export function fetchPurchaseHistorySuccess(data) {
  return {
    type: FETCH_PURCHASE_HISTORY_SUCCESS,
    data,
  };
}

export function fetchPurchaseHistoryFailure(error) {
  return {
    type: FETCH_PURCHASE_HISTORY_FAILURE,
    error,
  };
}

// Get user single purchase history actions.

export function fetchSinglePurchaseHistoryStart(data) {
  return {
    type: FETCH_SINGLE_PURCHASE_HISTORY_START,
    data,
  };
}

export function fetchSinglePurchaseHistorySuccess(data) {
  return {
    type: FETCH_SINGLE_PURCHASE_HISTORY_SUCCESS,
    data,
  };
}

export function fetchSinglePurchaseHistoryFailure(error) {
  return {
    type: FETCH_SINGLE_PURCHASE_HISTORY_FAILURE,
    error,
  };
}
