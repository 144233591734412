import {
    FETCH_ALL_ORDERS_START,
    FETCH_ALL_ORDERS_SUCCESS,
    FETCH_ALL_ORDERS_FAILURE,
    FETCH_SINGLE_ORDER_START,
    FETCH_SINGLE_ORDER_SUCCESS,
    FETCH_SINGLE_ORDER_FAILURE,
    ORDER_STATUS_UPDATE_START,
    ORDER_STATUS_UPDATE_SUCCESS,
    ORDER_STATUS_UPDATE_FAILURE,
  } from "./ActionConstant";
  
  // Get all orders actions.
  
  export function fetchAllOrdersStart(data) {
    return {
      type: FETCH_ALL_ORDERS_START,
      data,
    };
  }
  
  export function fetchAllOrdersSuccess(data) {
    return {
      type: FETCH_ALL_ORDERS_SUCCESS,
      data,
    };
  }
  
  export function fetchAllOrdersFailure(error) {
    return {
      type: FETCH_ALL_ORDERS_FAILURE,
      error,
    };
  }
  
  // Get single products actions.
  
  export function fetchSingleOrderStart(data) {
    return {
      type: FETCH_SINGLE_ORDER_START,
      data,
    };
  }
  
  export function fetchSingleOrderSuccess(data) {
    return {
      type: FETCH_SINGLE_ORDER_SUCCESS,
      data,
    };
  }
  
  export function fetchSingleOrderFailure(error) {
    return {
      type: FETCH_SINGLE_ORDER_FAILURE,
      error,
    };
  }
  
  export function orderStatusUpdate(data) {
    return {
      type: ORDER_STATUS_UPDATE_START,
      data,
    };
  }
  
  export function orderStatusUpdateSuccess(data) {
    return {
      type: ORDER_STATUS_UPDATE_SUCCESS,
      data,
    };
  }

  export function orderStatusUpdateFailure(error) {
    return {
      type: ORDER_STATUS_UPDATE_FAILURE,
      error,
    };
  }