import React, { Component } from "react";
import { fetchSingleOrderStart,orderStatusUpdate } from "../../store/actions/OrderAction";
import { connect } from "react-redux";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

class SingleOrderIndex extends Component {
  state = {
    cartInput: {
      quantity: 1,
    },
  };
  componentDidMount() {
    this.props.dispatch(
        fetchSingleOrderStart({ store_order_id: this.props.match.params.id })
    );
  }

  orderStatusUpdate = ({ currentTarget: input }) => {
    this.props.dispatch(orderStatusUpdate({status:input.value,store_order_id:this.props.match.params.id}));
  };

  render() {
    const { singleOrder } = this.props.orders;
    // console.log(this.props.orders.singleOrder.data.store_orders);
    if (singleOrder.loading) 
        return "Loading...";
    else
      return (
        <>  
          <div className="col-md-8">
            <div className="form-group">
              <label htmlFor="status">Choose Status:</label>

              <select
                name="status"
                id="status"
                onChange={this.orderStatusUpdate}
                className="form-control"
              >
                <option value="">Select</option>
                <option value="4">Order Cancelled</option>
                <option value="5">Order Delivered</option>
              </select>
            </div>
          </div>
            <div className="col-md-6">
                <div className="card card-user">
                    <div className="card-body">
                        <h4>Order Details</h4>
                        <h6>Order ID: {singleOrder.data.store_orders.unique_id}</h6>
                        <h6>Order Placed date: {singleOrder.data.store_orders.order_placed_date}</h6>
                        <h6>Status: {singleOrder.data.store_orders.status_formatted}</h6>
                        <h6>Amount: {singleOrder.data.store_orders.total_formatted}</h6>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card card-user">
                    <div className="card-body">
                    <h4>Product Details</h4>
                    {singleOrder.data.store_order_products.map((orders, i) => (
                        <div>
                        <h6>{orders.product_name} - ({orders.quantity}) {orders.total_formatted}</h6>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </>
      );
  }
}

const mapStateToPros = (state) => ({
    orders: state.orders,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleOrderIndex);
