import React, { Component } from "react";
import { fetchAllProductsStart } from "../../store/actions/ProductAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { addToCartStart } from "../../store/actions/CartAction";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

class ProductListIndex extends Component {
  state = {
    setIsFetchingAllProd : false,
    setLoadMoreData:true,
  };
  componentDidMount() {
    if (this.props.products.allProducts.loading) {
      this.props.dispatch(fetchAllProductsStart());
    }
  }
  addToCart = (event, products) => {
    event.preventDefault();
    if (localStorage.getItem("customerID") != undefined) {
      const inputData = {
        store_product_id: products.store_product_id,
        user_id: 1,
        quantity: 1,
        status: 0,
      };
      this.props.dispatch(addToCartStart(inputData));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Customer Id not found."
      );
      this.props.dispatch(createNotification(notificationMessage));
    }
  };
  
  loadMoreProduct() {
    this.setState({
      setIsFetchingAllProd: true,
    });
    if (
      this.props.products.totalItem !=
      this.props.products.allProducts.data.length
    ) {
      this.props.dispatch(fetchAllProductsStart());
      setTimeout(() => {
        this.setState({
          setIsFetchingAllProd: true,
        });
      }, 1000);
    } else {
      this.setState({
        setIsFetchingAllProd: false,
        setLoadMoreData:false,
      });
    }
  }

  render() {
    const { allProducts } = this.props.products;
    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Product lists</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive product-list-tabel">
              <table className="table">
                <thead className=" text-primary">
                  <th>Sl.no</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Amount</th>
                  <th>Avialablity</th>
                  <th>Quantity</th>
                  <th>Accept Call</th>
                </thead>
                <tbody>
                  {allProducts.loading
                    ? "Loading..."
                    : allProducts.data.length > 0
                    ? allProducts.data.map((products, i) => (
                        <tr>
                          <td>{i+1}</td>
                          <td>
                            {" "}
                            <img
                              className="avatar border-gray"
                              src={products.picture}
                              alt="..."
                            />
                          </td>
                          <td>{products.name}</td>
                          <td>{products.product_amount_formatted}</td>
                          <td>
                            {products.product_stock_status == 1
                              ? "Available"
                              : "Out of Stock"}
                          </td>
                          <td>{products.quantity}</td>
                          <td className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2 btn-round"
                              onClick={(event) =>
                                this.addToCart(event, products)
                              }
                            >
                              Add to Cart
                            </button>
                            <Link
                              to={`/store-product-details/${products.store_product_id}`}
                              className="btn btn-primary-outline btn-round"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    : "No data found"}
                </tbody>
              </table>
              <button
                  className="btn btn-primary mr-2 btn-round"
                  type="submit"
                  onClick={() => this.loadMoreProduct()}
                >
                  Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  products: state.products,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProductListIndex);
