import {
  FETCH_ALL_PRODUCTS_START,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_ALL_PRODUCTS_FAILURE,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILURE,
} from "./ActionConstant";

// Get all products actions.

export function fetchAllProductsStart(data) {
  return {
    type: FETCH_ALL_PRODUCTS_START,
    data,
  };
}

export function fetchAllProductsSuccess(data) {
  return {
    type: FETCH_ALL_PRODUCTS_SUCCESS,
    data,
  };
}

export function fetchAllProductsFailure(error) {
  return {
    type: FETCH_ALL_PRODUCTS_FAILURE,
    error,
  };
}

// Get single products actions.

export function fetchSingleProductStart(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_START,
    data,
  };
}

export function fetchSingleProductSuccess(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_SUCCESS,
    data,
  };
}

export function fetchSingleProductFailure(error) {
  return {
    type: FETCH_SINGLE_PRODUCT_FAILURE,
    error,
  };
}
